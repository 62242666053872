<template>
  <div id="header" class="header">
    <div id="account-menu" class="account-menu">
      <div class="account-sub-menu">
        <router-link class="account-sub-menu-router" to="/member/account">
          アカウント情報
        </router-link>
      </div>
      <div class="account-sub-menu">
        <router-link class="account-sub-menu-router" to="/member/updatepw">
          パスワード変更
        </router-link>
      </div>
      <div class="account-sub-menu" @click="signout">ログアウト</div>
    </div>
    <div v-on:click="toRingo" class="logo">
      <img class="" src="../assets/site_images/logo.png" alt="logo">
    </div>
    <div class="header-menu">
      <router-link id="home" class="menu menu-en" to="/">
        Home
      </router-link>
      <router-link id="home" class="menu menu-en" to="/music">
        Music
      </router-link>
      <router-link class="menu menu-en" to="/musicLP">
				Youtube
      </router-link>
      <router-link class="menu menu-en" to="/member/seminar">
        Member
      </router-link>
      <!-- <router-link class="menu menu-ja" to="/products">
        チケット販売
      </router-link> -->
      <!-- <a class="menu menu-en" href="/lp/" target="_blank">LP</a> -->
      <div id="account" @click="dispMenu" class="account">
        <ion-icon class="account-icon" name="person-circle-outline"></ion-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

export default {
  name: 'Header',
  props: {},
  mounted() {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        document.getElementById("account").style.display = "grid";
      }
    });
  },
  methods: {
    toRingo() {
      this.$router.push("/");
    },
    signout() {
      const auth = getAuth();
      signOut(auth)
      .then(() => {
        document.getElementById("account").style.display = "none";
        document.getElementById("account-menu").style.display = "none";
        document.getElementById("home").click();
      })
      .catch((error) => {
        console.log(error);
      });
    },
    dispMenu() {
      if (document.defaultView.getComputedStyle(document.getElementById('account-menu'), null).display == "none") {
        document.getElementById("account-menu").style.display = "grid";
      } else {
        document.getElementById("account-menu").style.display = "none";
      }
    },
  }
}
</script>

<style scoped>
.account {
  display: none;
  cursor: pointer;
  color: white;
}
.account-icon {
  width: 2vw;
  height: 2vw;
}
.account-menu {
  position: absolute;
  top: 2vw;
  right: 3vw;
  background-color: aliceblue;
  display: none;
}
.account-sub-menu {
  font-family: 'Kiwi Maru', cursive;
  margin: 0.8vw;
  font-size: 0.8vw;
  cursor: pointer;
}
.account-sub-menu-router {
  text-decoration: none;
  color: unset;
}
.logo {
  cursor: pointer;
}
.logo img {
	position: absolute;
  width: 1.8vw;
  top: 1vw;
  left: 1vw;
}
.header-menu {
  position: absolute;
  top: 2vw;
  right: 2vw;
  display: inline-flex;
}
.menu {
  font-size: 1.2vw;
  text-decoration: none;
  color: white;
  margin-right: 2vw;
}
.menu-en {
  font-family: 'Aboreto', cursive;
}
.menu-ja {
  font-family: "Kaisei Decol", serif;
}
.menu-icon {
  width: 20px;
  height: auto;
  top: 2px;
  position: relative;
  visibility: hidden;
}
@media screen and (max-width: 640px) {
  .header {
    box-shadow: unset;
    margin-bottom: 0.5vw;
    display: inline-flex;
    width: 100%;
    height: 10vw;
		position: absolute;
		background-color: white;
  }
  .account {
		position: unset;
    top: unset;
    right: 0;
    display: none;
    color: black !important;
  }
  .account-icon {
    width: 7vw;
    height: 7vw;
  }
  .account-menu {
    position: absolute;
    top: 2vw;
    right: 10vw;
    z-index: 999;
  }
  .account-sub-menu {
    font-family: 'Kiwi Maru', cursive;
    margin: 4vw;
    font-size: 4vw;
  }
  .logo {
    position: absolute;
    margin: unset;
    padding: unset;
    width: 5vw;
    height: auto;
    top: 1.5vw;
    left: 1.5vw;
  }
	.logo img {
		position: absolute;
		width: 8vw;
		height: auto;
		top: 1vw;
		left: 1vw;
	}
  .header-menu {
    padding-bottom: unset;
    margin: unset;
  }
  .menu {
		margin: 0 1vw;
    font-size: 17px;
    color: black !important;
  }
}
</style>
