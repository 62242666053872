import { createApp } from 'vue'
import App from './App.vue'
import router from './route/router.js'
import firebase from './config/firebase.js'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'
import './registerServiceWorker'
import Vue3EasyDataTable from 'vue3-easy-data-table';

const db = getFirestore(firebase);
const storage = getStorage(firebase);
const analytics = getAnalytics(firebase);
const functions = getFunctions(firebase);
functions.region = 'asia-northeast1';

export default { db, storage, analytics, functions };

let app = createApp(App);
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('ion-')
                                                   || tag.startsWith('vue-advanced-chat')
                                                   || tag.startsWith('emoji-picker');

app
	.component('EasyDataTable', Vue3EasyDataTable)
	.use(firebase)
	.use(router)
	.mount('#app')
