<template>
  <div class="music">
    <div class="tblWrap">
      <table id="tbl">
        <tr>
          <th>No</th>
          <th>タイトル</th>
					<th>サムネイル</th>
          <th>ライセンス状況</th>
          <th>配信</th>
          <th>視聴</th>
        </tr>
        <tr class="musicTr" v-for="music in musics" :key="music">
					<td>{{ music.no }}</td>
          <td v-if="music.titleJa">{{ music.titleJa }}</td>
          <td v-else>{{ music.tentativeTitle }}（仮）</td>
          <td class="imgTd">
						<img v-if="music.imgUrl" :src="music.imgUrl" alt="">
					</td>
          <td v-if="music.lisence">使用中</td>
          <td v-else>空き</td>
          <td>{{ music.status }}</td>
          <td class="audioTd"><audio controls controlsList="nodownload" :src="music.storageUrl"/></td>
        </tr>
      </table>
    </div>
		<div class="mobile">
			<div class="mDiv" v-for="music in musics" :key="music">
				<div class="mDivTitle">
          <div v-if="music.titleJa">{{ music.no }}. {{ music.titleJa }}</div>
          <div v-else>{{ music.no }}. {{ music.tentativeTitle }}（仮）</div>
				</div>
				<div class="mDivContent">
					<img class="mDivImg" v-if="music.imgUrl" :src="music.imgUrl" alt="">
					<div class="mDivText">
						<div v-if="music.lisence">ライセンス：使用中</div>
						<div v-else>ライセンス：空き</div>
						<div>配信状況：{{ music.status }}</div>
					</div>
				</div>
				<audio controls controlsList="nodownload" :src="music.storageUrl"/>
				<div class="snsIcons">
					<a v-if="music.youtube" :href="music.youtube">
						<img class="snsIcon" src="../../assets/icons/youtube.png" alt="">
					</a>	
					<a v-if="music.tiktok" :href="music.tiktok">
						<img class="snsIcon" src="../../assets/icons/tiktok.png" alt="">
					</a>	
					<a v-if="music.tiktok" :href="music.instagram">
						<img class="snsIcon" src="../../assets/icons/instagram.png" alt="">
					</a>	
				</div>
			</div>
		</div>
    <img id="matchaLogo" src="../../assets/site_images/mattya_walk_small.png" alt="">
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, getDocs, query } from 'firebase/firestore';

export default {
  name: 'Music',
  props: {
    msg: String
  },
  data() {
    return {
      musics: [],
    }
  },
  mounted() {
    this.awaitFunc();

		document.getElementById("account").style.color = "white";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "white";
		});
    
    let ringoHeight = window.innerHeight - document.getElementsByClassName("footer")[0].clientHeight;
    document.getElementsByClassName("music")[0].style.height = ringoHeight + "px";

		let mobileHeight = window.innerHeight - document.getElementsByClassName("header")[0].clientHeight - document.getElementsByClassName("footer")[0].clientHeight - 30;
		document.getElementsByClassName("mobile")[0].style.height = mobileHeight + "px";
  },
  methods: {
    async awaitFunc() {
      this.musics = [];
      const musicsQuerySnapshot = await getDocs(query(collection(firebase.db, "Musics")));
      musicsQuerySnapshot.forEach(async(docu) => {
        let data = docu.data();
        data.updateDateFmt = this.dateFormat(data.updateDate);
        this.musics.push(data);
      });

      this.musics.sort((a,b) => {
        return a.no - b.no;
      });
    },
    dateFormat(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "." + ("00" + (date.getMonth()+1)).slice(-2) + "." + ("00" + date.getDate()).slice(-2) + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table {
  position: relative;
  width: 100%;
	border-collapse: separate;
	border-spacing: 2px;
}
th {
  background-color: #4B663B;
  color: aliceblue;
  height: 2vw;
	border-radius: 5px;
	vertical-align: middle;
}
td {
  background-color: rgba(241, 243, 244, 1);
  padding: 0 2vw;
	border-radius: 5px;
	vertical-align: middle;
}
audio {
	vertical-align: top;
}
.audioTd {
  padding: 0 !important;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
#matchaLogo {
  position: fixed;
  height: 8vw;
  bottom: 2vw;
  right: 2vw;
  width: auto;
}
.music {
  font-family: "Kaisei Decol", serif;
	background: url("../../assets/site_images/bg/bg.jpg");
  background-size: cover;
}
.tblWrap {
  margin: 5vw auto 0;
	width: 60%;
	height: 720px;
	overflow-y: auto;
}
img {
	width: 2vw;
	height: auto;
}
.imgTd {
	padding: 0;
}
.mobile {
	display: none;
}
@media screen and (max-width:640px) {
  .music {
    width: 100%;
		background: url("../../assets/site_images/bg/bg_mobile.jpg");
		background-size: cover;
  }
  #matchaLogo {
    display: none;
  }
	.tblWrap {
		display: none;
	}
	.mobile {
		display: grid;
		margin-top: 12vw;
		overflow-y: auto;
	}
	.mDiv {
		text-align: left;
		width: 90%;
		margin: 0 auto 4vw;
		background-color: rgba(241, 243, 244, 1);
		border-radius: 7px;
		position: relative;
	}
	.mDivTitle {
		font-weight: bold;
		margin-left: 2vw;
		font-size: 1.2rem;
	}
	.mDivContent {
		display: inline-flex;
	}
	audio {
		width: 100%;
    height: 30px;
    margin: 0 auto;
	}
	.mDivImg {
    width: 4rem;
    height: auto;
		margin: 0 3vw;
	}
	.mDivText {
		padding-left: 2vw;
	}
	.snsIcons {
		position: relative;
    display: block;
    margin-top: 2vw;
    float: right;
    margin-right: 3vw;
    margin-bottom: 2vw;
	}
	.snsIcon {
    width: 12vw;
    height: auto;
    padding: 0 2vw;
	}
}
</style>